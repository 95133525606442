.App {
  text-align: center;
}

.astronimals-main-connect-sec {
  text-align: center;
  background: #000000c1;
  max-width: 500px;
  max-height: 500px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 25px auto 0;
  padding: 34px 0 50px;
  display: flex;
  border: 6px solid #695e8d;
  border-radius: 16px;
  position: relative;
}

.banner {
  text-align: center;
  /* align-items: center;
  justify-content: center; */
  display: inline-table;
  position: relative;
}

.banner img {
  /* border: 6px solid #695e8d; */
  /* border-radius: 16px; */
  position: relative;
  top: -15px;
}

.banner p {
  top: -25px;
  font-size: 38px;
  color: white;
  font-weight: bold;
  position: relative;
}

.banner span {
  top: -25px;
  font-size: 18px;
  color: white;
  position: relative;
}

.dragon-icons {
  position: absolute;
  right: 70px;
  top: 50px;
}

.frog-icons {
  position: absolute;
  left: 70px;
  top: 250px;
}

.fish-icons {
  position: absolute;
  right: 220px;
  top: 350px;
}